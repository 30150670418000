.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh;
}

video {
  border: 3px solid white;
  border-radius: 10px;
  height: 40vh;
  width: 100vh;

}
